<template>
  <b-container fluid>
    <bo-page-title />
    <section id="invrelate">
      <b-tabs>
        <b-tab title="Page Settings">
          <bo-card title="Hero Image">
            <b-row>
              <b-col md="4">
                <bo-card-img title="Calculator" :src="uploader(heroImage.ssf_file, '/assets/images/no-slide.jpg')">
                  <template #buttons>
                    <b-button
                      variant="secondary"
                      size="sm"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Update'"
                      @click="changeHero()"
                      v-if="moduleRole('updateHero')"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                  </template>
                </bo-card-img>
              </b-col>
            </b-row>

            <b-modal 
              v-model="heroEditorOpen"
              :title="'Update Hero Image '+ pageTitle"
              size="lg"
              hide-footer
              body-class="p-0"
            >
              <validation-observer
                v-slot="{ handleSubmit }" ref="VFormHero"
              >
                
                <b-form @submit.prevent="handleSubmit(submitHero)">
                  <b-card-body>
                    <b-row>
                      <b-col md="6">
                        <div class="file-uploader">
                          <label>Hero Image Desktop: </label>
                          <Uploader v-model="heroImage.ssf_file" type="hero-calculator"/>
                          <VValidate 
                            name="Hero Image Desktop" 
                            v-model="heroImage.ssf_file"
                            :rules="validationHeroImage.ssf_file" 
                          />
                        </div>
                        
                        <div class="file-uploader">
                          <label>Hero Image Mobile: </label>
                          <Uploader v-model="heroImage.ssf_file_mobile" type="hero-calculator-mobile" use-preview />
                          <VValidate 
                            name="Hero Image Mobile" 
                            v-model="heroImage.ssf_file_mobile"
                            :rules="validationHeroImage.ssf_file_mobile" 
                          />
                        </div>
                        
                      </b-col>
                      <b-col md="6">
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (ID)</h5>
                          </template>
                          <b-form-group label-for="heroTitleId">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="heroTitleId" v-model="heroImage.ssf_title_id" @input="autoFill('ssf_alt_mobile_id','ssf_alt_id','ssf_title_id')"/>
                            <VValidate 
                              name="Content ID" 
                              v-model="heroImage.ssf_title_id"
                              :rules="{...validationHeroImage.ssf_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altId">
                            <template #label>Alt Image <strong class="text-danger">*</strong></template>
                            <b-form-input id="altId" v-model="heroImage.ssf_alt_id" />
                            <VValidate 
                              name="Alt Image Id" 
                              v-model="heroImage.ssf_alt_id"
                              :rules="{...validationHeroImage.ssf_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                        
                          <b-form-group label-for="altIdM">
                            <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                              <b-form-input id="altIdM" v-model="heroImage.ssf_alt_mobile_id" />
                              <VValidate 
                                name="Alt Image Mobile ID" 
                                v-model="heroImage.ssf_alt_mobile_id"
                                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>  
                        </b-card>
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (EN)</h5>
                          </template>
                          <b-form-group label-for="heroTitleEn">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="heroTitleEn" @input="autoFill('ssf_alt_mobile_en','ssf_alt_en','ssf_title_en')" v-model="heroImage.ssf_title_en"/>
                            <VValidate 
                              name="Content EN" 
                              v-model="heroImage.ssf_title_en"
                              :rules="{...validationHeroImage.ssf_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altEN">                          
                            <template #label>Alt Image <strong class="text-danger">*</strong></template>
                            <b-form-input id="altEN" v-model="heroImage.ssf_alt_en" />
                            <VValidate 
                              name="Alt Image En" 
                              v-model="heroImage.ssf_alt_en"
                              :rules="{...validationHeroImage.ssf_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                          <b-form-group label-for="altEnM">
                            <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                            <b-form-input id="altIEn" v-model="heroImage.ssf_alt_mobile_en" />
                            <VValidate 
                              name="Alt Image Mobile EN" 
                              v-model="heroImage.ssf_alt_mobile_en"
                              :rules="{required: 1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-card-footer>
                    <div class="text-right">
                      <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
                    </div>
                  </b-card-footer>
                </b-form>
              </validation-observer>
            </b-modal>
          </bo-card>
          
          <b-tabs>
            <b-tab title="General Settings">
              <b-form @submit.prevent>
                <b-card no-body>
                  <b-tabs card>
                    <b-tab title="Settings for Comprehensive">
                      <!--
                      <b-alert show variant="warning">
                        <i class="fas fa-exclamation-circle mr-2"></i>
                        You have unsaved changes. Click <strong>Save Changes</strong> to save your changes.
                      </b-alert>
                      -->
                      <b-row>
                        <b-col md="3">
                          <b-form-group label-for="compreMaxAge">
                            <template #label>Vehicle Max Age <strong class="text-danger">*</strong></template>
                            <b-input-group append="Years">
                              <b-form-input v-model="dataComp.limitYear" :formatter="value => parseInt((value || 0))" placeholder="e.g. 4" id="compreMaxAge" />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <div class="w-100"></div>
                        <b-col md="7">
                          <b-card no-body class="border">
                            <template #header><h5 class="card-title">Rates</h5></template>
                            <b-card-body>
                              <b-form-row class="align-items-center">
                                <label class="col-md-12">Show rates for: </label>
                                <b-col md="6">
                                  <v-select
                                    v-model="compreArea"
                                    :options="areaCategories"
                                    :reduce="v => v.id"
                                    @input="doFilterComp"
                                  />
                                </b-col>
                                <b-col md="6">
                                  <v-select
                                    v-model="compreCat"
                                    :options="dataComp.catOptions"
                                    :reduce="v => v.id"
                                    @input="doFilterComp"
                                  />
                                </b-col>
                              </b-form-row>
                            </b-card-body>
                            <b-table-simple class="rate-table" bordered>
                              <b-tbody>
                                <b-tr>
                                  <b-td>
                                    <b-form-group>
                                      <template #label>Main Cover Rate <strong class="text-danger">*</strong></template>
                                      <b-input-group append="%">
                                        <b-form-input v-model="dataComp.rate" placeholder="e.g. 0,17" />
                                      </b-input-group>
                                    </b-form-group>
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th colspan="99">Additional Guarantees Rates</b-th>
                                </b-tr>
                                <b-tr v-for="(v,k) in dataComp.rateAdd" :key="k">
                                  <b-td>
                                    <b-form-group>
                                      <template #label>{{v.name}} (lower limit)<strong class="text-danger">*</strong></template>
                                      <b-input-group append="%">
                                        <b-form-input v-model="v.lower_limit" placeholder="e.g. 0,17" />
                                      </b-input-group>
                                    </b-form-group>
                                  </b-td>
                                  <b-td>
                                    <b-form-group>
                                      <template #label>{{v.name}} (upper limit)</template>
                                      <b-input-group append="%">
                                        <b-form-input v-model="v.upper_limit" placeholder="e.g. 0,17" />
                                      </b-input-group>
                                    </b-form-group>
                                  </b-td>
                                </b-tr>
                              </b-tbody>
                              <b-tfoot>
                                <b-tr>
                                  <b-td colspan="99">
                                    <div class="text-center">
                                      <b-button variant="outline-primary" class="btn-rounded" @click="openAddGuaranteeForm()">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        Add Additional Guarantee
                                      </b-button>
                                    </div>
                                  </b-td>
                                </b-tr>
                              </b-tfoot>
                            </b-table-simple>
                          </b-card>
                        </b-col>
                        <b-col md="5">
                          <b-card class="border">
                            <template #header>
                              <h5 class="card-title">Category Limit</h5>
                            </template>
                            <b-form-group v-for="(v,k) in dataComp.limit" :key="k">
                                <template #label>Limit for {{v.mccr_category_name}} <strong class="text-danger">*</strong></template>
                                <b-input-group prepend="IDR" class="mb-3">
                                  <b-form-input placeholder="e.g. 2500000" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" v-model="v.mccr_start_value" />
                                </b-input-group>
                              
                                <template #label>Limit for {{v.mccr_category_name}} <strong class="text-danger">*</strong></template>
                                <b-input-group prepend="IDR">
                                  <b-form-input placeholder="e.g. 2500000" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" v-model="v.mccr_end_value" />
                                </b-input-group>
                            </b-form-group>
                          </b-card>
                        </b-col>
                      </b-row>
                      <template>
                        <div class="text-right">
                          <!--
                          <b-button class="btn-rounded mr-2" variant="secondary">Cancel</b-button>
                          -->
                          <b-button @click="doSubmitComp" class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                        </div>
                      </template>
                    </b-tab>




                    <b-tab title="Settings for Total Loss Only">
                      <!--
                      <b-alert show variant="warning">
                        <i class="fas fa-exclamation-circle mr-2"></i>
                        You have unsaved changes. Click <strong>Save Changes</strong> to save your changes.
                      </b-alert>
                      -->
                      <b-row>
                        <b-col md="3">
                          <b-form-group label-for="tloMaxAge">
                            <template #label>Vehicle Max Age <strong class="text-danger">*</strong></template>
                            <b-input-group append="Years">
                              <b-form-input v-model="dataTLO.limitYear" placeholder="e.g. 4" id="tloMaxAge" />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <div class="w-100"></div>
                        <b-col md="7">
                          <b-card no-body class="border">
                            <template #header><h5 class="card-title">Rates</h5></template>
                            <b-card-body>
                              <b-form-row class="align-items-center">
                                <label class="col-md-12">Show rates for: </label>
                                <b-col md="6">
                                  <v-select
                                    v-model="tloArea"
                                    :options="areaCategories"
                                    :reduce="v => v.id"
                                    @input="doFilterComp"
                                  />
                                </b-col>
                                <b-col md="6">
                                  <v-select
                                    v-model="tloCat"
                                    :options="dataTLO.catOptions"
                                    :reduce="v => v.id"
                                    @input="doFilterComp"
                                  />
                                </b-col>
                              </b-form-row>
                            </b-card-body>
                            <b-table-simple class="rate-table" bordered>
                              <b-tbody>
                                <b-tr>
                                  <b-td>
                                    <b-form-group>
                                      <template #label>Main Cover Rate <strong class="text-danger">*</strong></template>
                                      <b-input-group append="%">
                                        <b-form-input v-model="dataTLO.rate" placeholder="e.g. 0,17" />
                                      </b-input-group>
                                    </b-form-group>
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th colspan="99">Additional Guarantees Rates</b-th>
                                </b-tr>
                                <b-tr v-for="(v,k) in dataTLO.rateAdd" :key="k">
                                  <b-td>
                                    <b-form-group>
                                      <template #label>{{v.name}} (lower limit)<strong class="text-danger">*</strong></template>
                                      <b-input-group append="%">
                                        <b-form-input v-model="v.lower_limit" placeholder="e.g. 0,17" />
                                      </b-input-group>
                                    </b-form-group>
                                  </b-td>
                                  <b-td>
                                    <b-form-group>
                                      <template #label>{{v.name}} (upper limit)</template>
                                      <b-input-group append="%">
                                        <b-form-input v-model="v.upper_limit" placeholder="e.g. 0,17" />
                                      </b-input-group>
                                    </b-form-group>
                                  </b-td>
                                </b-tr>
                              </b-tbody>
                              <b-tfoot>
                                <b-tr>
                                  <b-td colspan="99">
                                    <div class="text-center">
                                      <b-button variant="outline-primary" class="btn-rounded" @click="openAddGuaranteeForm()">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        Add Additional Guarantee
                                      </b-button>
                                    </div>
                                  </b-td>
                                </b-tr>
                              </b-tfoot>
                            </b-table-simple>
                          </b-card>
                        </b-col>
                        <b-col md="5">
                          <b-card class="border">
                            <template #header>
                              <h5 class="card-title">Category Limit</h5>
                            </template>
                            <b-form-group v-for="(v,k) in dataTLO.limit" :key="k">
                                <template #label>Limit for {{v.mccr_category_name}} <strong class="text-danger">*</strong></template>
                                <b-input-group prepend="IDR" class="mb-3">
                                  <b-form-input placeholder="e.g. 2500000" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" v-model="v.mccr_start_value" />
                                </b-input-group>
                              
                                <template #label>Limit for {{v.mccr_category_name}} <strong class="text-danger">*</strong></template>
                                <b-input-group prepend="IDR">
                                  <b-form-input placeholder="e.g. 2500000" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" v-model="v.mccr_end_value" />
                                </b-input-group>
                            </b-form-group>
                          </b-card>
                        </b-col>
                      </b-row>
                      <template>
                        <div class="text-right">
                          <!--
                          <b-button class="btn-rounded mr-2" variant="secondary">Cancel</b-button>
                          -->
                          <b-button @click="doSubmitTLO" class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                        </div>
                      </template>
                    </b-tab>




                    <b-tab title="Other Settings">
                      <!--
                      <b-alert show variant="warning">
                        <i class="fas fa-exclamation-circle mr-2"></i>
                        You have unsaved changes. Click <strong>Save Changes</strong> to save your changes.
                      </b-alert>
                      -->
                      <b-form @submit.prevent="doSubmitPolicy()">
                      <b-row>
                        <b-col md="3">
                          <b-form-group label-for="compreMaxAge">
                            <template #label>Policy Cost &amp; Stamp Duty <strong class="text-danger">*</strong></template>
                            <b-input-group prepend="IDR">
                              <b-form-input v-model="dataPS.value" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" placeholder="e.g. 60000" id="compreMaxAge" />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <template>
                        <div class="text-right">
                          <!--
                          <b-button class="btn-rounded mr-2" variant="secondary">Cancel</b-button>
                          -->
                          <b-button @click="doSubmitPolicy" class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                        </div>
                      </template>
                      </b-form>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-form>
            </b-tab>




            <b-tab title="Area Plate Code &amp; Category">
              <b-card no-body>
                <template #header>
                  <div class="d-flex justify-content-between">
                    <h5 class="card-title mt-2">Area List</h5>
                    <b-button class="btn-rounded" variant="success" @click="openAreaForm()">
                      <i class="fas fa-plus-circle mr-2"></i>
                      Add Area
                    </b-button>
                  </div>
                </template>
                <b-card-body>
                  <b-row>
                    <b-col md>
                      <b-form-row>
                        <b-col md="4">
                          <v-select
                            :options="areaFilterCategories"
                            v-model="filterArea"
                            @input="doFilterArea()" 
                            :reduce="v => v.id"
                          />
                        </b-col>
                        <b-col md="3">
                          <v-select
                            v-model="filterActiveArea"
                            :options="statusOptions"
                            @input="doFilterActiveArea()" 
                            :reduce="v => v.value"
                          />
                        </b-col>
                      </b-form-row>
                    </b-col>
                    <b-col md="auto">
                        <div class="d-flex">
                            <b-form @submit.prevent="doFilter('calculator-plat',filter)" class="d-flex">
                                <b-input-group>
                                <b-form-input v-model="filter.searchArea" v-on:keyup.13="$emit('searchArea')" placeholder="Type keyword then enter..."></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="doFilter('calculator-plat',filter)" variant="success"><i class="fas fa-search"></i></b-button>
                                </b-input-group-append>
                                </b-input-group>
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    @click="doReset('calculator-plat')"
                                >Reset</b-button>
                            </b-form>
                        </div>
                    </b-col>
                  </b-row>
                </b-card-body>
                <b-table
                  :fields="areaFields"
                  :items="(calculatorPlatData.data||[])"
                  :per-page="areaPerPage"
                  show-empty
                  responsive
                  striped
                  hover
                >
                
                  <template #cell(no)="v">
                    {{(calculatorPlatData.per_page*(calculatorPlatData.current_page-1))+v.index+1}}    
                  </template>
                  <template #cell(region)="data">
                    <span>{{ data.item.mcp_wilayah_plat_id }} ({{ data.item.mcp_code_plat }})</span>
                  </template>
                  <template #cell(cat)="data">WILAYAH {{ data.item.mcp_wilayah}} / AREA {{ data.item.mcp_wilayah}}</template>
                  <template #cell(status)="data">
                    <b-badge variant="success" v-if="data.item.mcp_is_active == 'Y'">Active</b-badge>
                    <b-badge variant="danger" v-else-if="data.item.mcp_is_active == 'N'">Inactive</b-badge>
                  </template>

                  <template #cell(actions)="data">
                    <b-button
                      class="btn-icon"
                      variant="outline-warning"
                     @click="openAreaForm(data.item, data.index)"
                      v-b-tooltip.hover="'Edit Data'"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </b-button>
                    <b-button
                      class="btn-icon"
                      variant="outline-primary"
                      @click="doChangeStatus('change-status-plat',data.item,'mcp_id','mcp_is_active')"
                      v-b-tooltip.hover="'Change Status'"
                    >
                      <i class="fas fa-cog"></i>
                    </b-button>
                    <b-button
                      class="btn-icon"
                      variant="outline-danger"
                      @click="doDelete('delete-plat',data.item,'mcp_id')"
                      v-b-tooltip.hover="'Delete'"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>

                <template #footer>
                  <b-row>
                    <b-col md>
                      <b-pagination
                        class="mb-0"
                        v-model="areaCurPage"
                        v-if="calculatorPlatData.total > areaPerPage"
                        :per-page="areaPerPage"
                        :total-rows="calculatorPlatData.total"
                        align="end"
                      />
                    </b-col>
                  </b-row>
                </template>
              </b-card>

            <validation-observer
                v-slot="{ handleSubmit }" ref="VFormArea"
            >
              <b-form @submit.prevent="handleSubmit(doSubmitArea(false))">
                <b-modal
                  id="formArea"
                  :title="input.index !== false ? 'Update ' + 'Workshop' : 'Add '+'Workshop'"        
                  cancel-variant="secondary btn-rounded"
                  ok-variant="primary btn-rounded"
                  ok-title="Save Changes"
                  @ok="doSubmitArea"
                  no-close-on-backdrop 
                >
                  <b-form-row>
                    <b-col md="12">
                      <b-form-group label-for="areaCategory">
                        <template #label>Category <strong class="text-danger">*</strong></template>
                        <v-select
                          placeholder="Select Area Category"
                          v-model="inputPlat.mcp_wilayah"
                          :options="areaCategories"
                          :reduce="v => v.id"
                        />
                        <VValidate 
                            name="Wilayah" 
                            v-model="inputPlat.mcp_wilayah" 
                            rules="required"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label-for="areaCode">
                        <template #label>Area Code <strong class="text-danger">*</strong></template>
                        <b-form-input
                          id="areaCode"
                          placeholder="e.g. AX"
                          v-model="inputPlat.mcp_code_plat"
                        />
                        <VValidate 
                            name="Area Code" 
                            v-model="inputPlat.mcp_code_plat" 
                            rules="required"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="8">
                      <b-form-group label-for="areaNameId">
                        <template #label>Area Name (ID) <strong class="text-danger">*</strong></template>
                        <b-form-input
                          id="areaNameId"
                          placeholder="e.g. Jakarta Barat"
                          v-model="inputPlat.mcp_wilayah_plat_id"
                        />
                        <VValidate 
                            name="Area Name ID" 
                            v-model="inputPlat.mcp_wilayah_plat_id" 
                            rules="required"
                        />
                      </b-form-group>
                      <!-- Form wilayah bahasa saling autofill satu sama lain -->
                      <b-form-group label-for="areaNameEn">
                        <template #label>Area Name (EN) <strong class="text-danger">*</strong></template>
                        <b-form-input
                          id="areaNameEn"
                          placeholder="e.g. West Jakarta"
                          v-model="inputPlat.mcp_wilayah_plat_en"
                        />
                        <VValidate 
                            name="Area Name EN" 
                            v-model="inputPlat.mcp_wilayah_plat_en" 
                            rules="required"
                        />
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-modal>
              </b-form>
            </validation-observer>
            </b-tab>




            <b-tab title="Third-party Liabilities">
              <b-card no-body>
                <template #header>
                  <h5 class="card-title">Third-party Liability Rate &amp; Limit</h5>
                </template>
                <validation-observer
                  v-slot="{ handleSubmit }" ref="VFormTP"
                >
                  <b-form @submit.prevent="handleSubmit(doSubmitTP(false))">
                    <b-card-body>
                      <!---
                      <b-alert show variant="warning">
                        <i class="fas fa-exclamation-circle mr-2"></i>
                        You have unsaved changes. Click <strong>Save Changes</strong> to save your changes.
                      </b-alert>
                      -->
                      <b-row>
                        <b-col md="4">
                          <b-form-row>
                            <b-col md="12">
                              <b-form-group label-for="thirdCat" label="Category">
                                <v-select
                                  :options="thirdCatOptions"
                                  v-model="thirdCat"
                                  :reduce="v => v.value"
                                  @input="doChangeTP"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="8">
                              <b-form-group label-for="thirdRate">
                                <template #label>Rate <strong class="text-danger">*</strong></template>
                                <b-input-group append="%">
                                  <b-form-input v-model="formCat.rate" type="number" placeholder="e.g. 0,12" min="0" max="100" step="0.01" />
                                </b-input-group>
                                <!--
                                <VValidate 
                                    name="Rate" 
                                    v-model="formCat.rate" 
                                    rules="required"
                                />
                                -->
                              </b-form-group>
                            </b-col>
                            <b-col md="12" v-if="thirdCat !== 'D' && thirdCat !== 'P'">
                              <b-form-group label-for="thirdLimit">
                                <template #label>Lower Limit <strong class="text-danger">*</strong></template>
                                <b-input-group prepend="IDR">
                                  <b-form-input v-model="formCat.lower_limit" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" id="thirdLimit" type="text" placeholder="e.g. 25000000" />
                                  <VValidate 
                                    name="Lower Limit" 
                                    v-model="formCat.lower_limit" 
                                    rules="required"
                                  />
                                </b-input-group>
                              </b-form-group>

                              <b-form-group label-for="thirdLimit">
                                <template #label>Upper Limit <strong class="text-danger">*</strong></template>
                                <b-input-group prepend="IDR">
                                  <b-form-input v-model="formCat.upper_limit" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" id="thirdLimit" type="text" placeholder="e.g. 25000000" />
                                </b-input-group>
                                <VValidate 
                                  name="Upper Limit" 
                                  v-model="formCat.upper_limit" 
                                  rules="required"
                                />
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                        </b-col>
                        <b-col md="8">
                          <b-card title="Quick Simulation" class="border mb-0">
                            <b-row>
                              <b-col md="4">
                                <b-form-group class="mb-2" label-for="dataSim" label-class="mb-1" label="Third-party Liability">
                                  <span>IDR. {{formatDecimal(tpLimit.val)}},00-</span>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group class="mb-2" label-for="dataSim" label-class="mb-1" label="Personal Accident Driver">
                                  <span>IDR. {{formatDecimal(tpLimit.PADDriver)}},00-</span>
                                </b-form-group>
                              </b-col>
                              <div class="w-100"></div>
                              <b-col md="4">
                                <b-form-group class="mb-2" label-for="dataSim" label-class="mb-1" label="Personal Accident Passenger(s)">
                                  <span>IDR. {{formatDecimal(tpLimit.PADPassanger)}},00-</span>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group class="mb-2" label-for="dataSim" label-class="mb-1" label="Number of Passengers (Excluding driver)">
                                  <span>{{tpLimit.passanger}}</span>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-table-simple small>
                              <b-thead>
                                <b-tr>
                                  <b-th>Cover</b-th>
                                  <b-th>Limit</b-th>
                                  <b-th>Rate</b-th>
                                  <b-th>Premium</b-th>
                                </b-tr>
                              </b-thead>
                              <b-tbody v-if="tpLimit.tpRates">
                                <b-tr v-if="tpLimit.tpRates['A']">                        
                                <b-td class="text-lowercase">Third-party Liability</b-td>
                                <b-td>A. IDR {{formatDecimal(tpLimit.tpRates['A'].limitRate)}},00-</b-td>
                                <b-td>{{tpLimit.tpRates['A'].rate}}%</b-td>
                                <b-td>IDR. {{formatDecimal(tpLimit.tpRates['A'].price)}},00-</b-td>
                                </b-tr>                        

                                <b-tr v-if="tpLimit.tpRates['B']">
                                <b-td></b-td>
                                <b-td>B. IDR {{formatDecimal(tpLimit.tpRates['B'].limitRate)}},00-</b-td>
                                <b-td>{{tpLimit.tpRates['B'].rate}}%</b-td>
                                <b-td>IDR. {{formatDecimal(tpLimit.tpRates['B'].price)}},00-</b-td>
                                </b-tr>

                                <b-tr v-if="tpLimit.tpRates['C']">
                                <b-td></b-td>
                                <b-td>C. IDR {{formatDecimal(tpLimit.tpRates['C'].limitRate)}},00-</b-td>
                                <b-td>{{tpLimit.tpRates['C'].rate}}%</b-td>
                                <b-td>IDR. {{formatDecimal(tpLimit.tpRates['C'].price)}},00-</b-td>
                                </b-tr>
                                
                                <b-tr v-if="tpLimit.tpRates['D']">
                                <b-td></b-td>                        
                                <b-td>D. IDR {{formatDecimal(tpLimit.tpRates['D'].limitRate)}},00-</b-td>
                                <b-td>{{tpLimit.tpRates['D'].rate}}%</b-td>
                                <b-td>IDR. {{formatDecimal(tpLimit.tpRates['D'].price)}},00-</b-td>
                                </b-tr>
                                
                                <b-tr>
                                <b-td class="text-lowercase">Third-party Liability (Total)</b-td>
                                <b-td></b-td>
                                <b-td></b-td>
                                <b-td>IDR. {{formatDecimal(tpLimit.hasilTP.totalThirdParty)}},00-</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td>Personal Accident Driver</b-td>
                                  <b-td>IDR {{formatDecimal(tpLimit.PADDriver)}},00</b-td>
                                  <b-td>{{tpLimit.hasilTP.rateDriver}}%</b-td>
                                  <b-td>IDR {{formatDecimal(tpLimit.hasilTP.driverValue)}},00</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td>Personal Accident Passenger</b-td>
                                  <b-td>IDR {{formatDecimal(tpLimit.PADPassanger)}},00</b-td>
                                  <b-td>{{tpLimit.hasilTP.ratePassanger}}% ({{tpLimit.hasilTP.ratePassangerOne}}% x {{tpLimit.passanger}})</b-td>
                                  <b-td>IDR {{formatDecimal(tpLimit.hasilTP.passangerValue)}},00</b-td>
                                </b-tr>
                              </b-tbody>
                            </b-table-simple>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-card-body>
                    <b-card-footer>
                      <div class="text-right">
                        <div class="text-right">
                          <!--
                          <b-button class="btn-rounded mr-2" variant="secondary">Cancel</b-button>
                          -->
                          <b-button type="submit" class="btn-rounded" variant="primary">Save Changes</b-button>
                        </div>
                      </div>
                    </b-card-footer>
                  </b-form>
                </validation-observer>
              </b-card>
            </b-tab>





            <b-tab title="Additional Guarantees">
              <b-card no-body>
                <template #header>
                  <div class="d-flex justify-content-between">
                    <h5 class="card-title mt-2">Additional Guarante</h5>
                    <b-button variant="success" class="btn-rounded" @click="openAddGuaranteeForm()">
                      <i class="fas fa-plus-circle mr-2"></i>
                      Additional Guarantee Form
                    </b-button>
                  </div>
                </template>
                <b-card-body>
                  <b-row>
                    <b-col md>
                      <b-form-row>
                        <b-col md="3">
                          <v-select
                            v-model="filterActiveGuarantee"
                            :options="statusOptions"
                            @input="dofilterActiveGuarantee()" 
                            :reduce="v => v.value"
                          />
                        </b-col>
                      </b-form-row>
                    </b-col>
                    <b-col md="auto">
                      <b-form @submit.prevent="doFilter('calculator-add',filter)" class="d-flex">
                          <b-input-group>
                          <b-form-input v-model="filter.searchGuarantee" v-on:keyup.13="$emit('searchGuarantee')" placeholder="Type keyword then enter..."></b-form-input>
                          <b-input-group-append>
                              <b-button @click="doFilter('calculator-add',filter)" variant="success"><i class="fas fa-search"></i></b-button>
                          </b-input-group-append>
                          </b-input-group>
                          <b-button
                              class="ml-1 d-inline-flex align-items-center"
                              variant="outline-success"
                              @click="doReset('calculator-add')"
                          >Reset</b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
                
                <b-table
                  :fields="addGuaranteeFields"
                  :items="calculatorAddData.data || []"
                  :per-page="addGuaranteePerPage"
                  show-empty
                  responsive
                  striped
                  hover
                >
                  <template #cell(no)="v">
                    {{(calculatorAddData.per_page*(calculatorAddData.current_page-1))+v.index+1}}                      
                  </template>
                  <template #cell(name)="data">
                    {{ data.item.mcag_name_id }} / {{ data.item.mcag_name_en }}
                    <div v-if="data.item.mcag_show_category == 'B'"><small>Used in <strong>Comprehensive, Total Loss Only</strong></small></div>
                    <div v-else-if="data.item.mcag_show_category == 'C'"><small>Used in <strong>Comprehensive</strong></small></div>
                    <div v-else-if="data.item.mcag_show_category == 'T'"><small>Used in <strong>Total Loss Only</strong></small></div>

                    <div v-if="(data.item.related||[]).length"><small>Also includes 
                    <strong class="text-primary">
                    {{ data.item.relatedList }}
                    </strong>
                    </small></div>
                  </template>
                  <template #cell(showCalc)="data">
                    <strong class="text-success" v-if="data.item.mcag_show_in_calculator == 'Y'">Yes</strong>
                    <strong class="text-danger" v-else-if="data.item.mcag_show_in_calculator == 'N'">No</strong>
                  </template>
                  <template #cell(status)="data">
                    <b-badge variant="success" v-if="data.item.mcag_is_active == 'Y'">Active</b-badge>
                    <b-badge variant="danger" v-else-if="data.item.mcag_is_active == 'N'">Inactive</b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      class="btn-icon"
                      variant="outline-warning"
                      v-b-tooltip.hover="'Edit Data'"
                      @click="openAddGuaranteeForm(data.item, data.index)"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </b-button>
                    <b-button
                      class="btn-icon"
                      variant="outline-primary"
                      @click="doChangeStatus('change-status-add',data.item,'mcag_id','mcag_is_active')"
                      v-b-tooltip.hover="'Change Status'"
                    >
                      <i class="fas fa-cog"></i>
                    </b-button>
                    <b-button
                      class="btn-icon"
                      variant="outline-danger"
                      @click="doDelete('delete-add',data.item,'mcag_id')"
                      v-b-tooltip.hover="'Delete'"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
                <template #footer>
                  <b-row>
                    <b-col md>
                      <b-pagination
                        class="mb-0"
                        v-model="addGuaranteeCurPage"
                        v-if="calculatorAddData.total > addGuaranteePerPage"
                        :per-page="addGuaranteePerPage"
                        :total-rows="calculatorAddData.total"
                        align="end"
                      />
                    </b-col>
                  </b-row>
                </template>
              </b-card>

            <validation-observer
                v-slot="{ handleSubmit }" ref="VFormGuarantee"
            >
              <b-form @submit.prevent="handleSubmit(doSubmitGuarantee(false))">
              <b-modal
                id="guaranteeForm"
                :title="inputAdd.mcag_id ? 'Update Additional Guarantee' : 'Add Additional Guarantee'"
                cancel-variant="secondary btn-rounded"
                ok-variant="primary btn-rounded"
                ok-title="Save Changes"
                @ok="doSubmitGuarantee"
                no-close-on-backdrop 
              >
                <b-form-group label-for="guaranteeNameId">
                  <template #label>Name (ID) <strong class="text-danger">*</strong></template>
                  <b-form-input id="guaranteeNameId" v-model="inputAdd.mcag_name_id" placeholder="e.g. Kebakaran" />
                  <VValidate 
                      name="Guarantee Name ID" 
                      v-model="inputAdd.mcag_name_id" 
                      rules="required"
                  />
                </b-form-group>
                <b-form-group label-for="guaranteeNameEn">
                  <template #label>Name (EN) <strong class="text-danger">*</strong></template>
                  <b-form-input id="guaranteeNameEn" v-model="inputAdd.mcag_name_en" placeholder="e.g. Fire" />
                  <VValidate 
                    name="Guarantee Name EN" 
                    v-model="inputAdd.mcag_name_en" 
                    rules="required"
                  />
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="inputAdd.showCal">Show in Calculator?</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <template #label>Available for <small>Choose at least 1 (one)</small></template>
                  <b-form-checkbox-group v-model="inputAdd.showCat" 
                  value-field="value"
                  text-field="item"
                  :options="guaranteeAvailability" />
                  <VValidate 
                    name="Category" 
                    v-model="inputAdd.showCat" 
                    rules="required"
                  />
                </b-form-group>
                <b-form-group>
                  <template #label>Also includes (optional)</template>
                  <b-form-checkbox-group v-model="inputAdd.relatedTo" :options="mrCalculatorAdd" 
                  value-field="value"                  
                  stacked />
                </b-form-group>
                <b-form-group>
                  <template #label>Status</template>
                  <div class="row">
                  <div class="col-md-3">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="customRadio1" v-model="inputAdd.mcag_is_active" value="Y"
                        name="radioRow" class="custom-control-input">
                      <label class="custom-control-label" for="customRadio1">Active</label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="customRadio3" v-model="inputAdd.mcag_is_active" value="N"
                        name="radioRow" class="custom-control-input">
                      <label class="custom-control-label" for="customRadio3">Inactive</label>
                    </div>
                  </div>
                  <VValidate name="Status" v-model="inputAdd.mcag_is_active"
                    :rules="mrValidation.mcag_is_active" />
                </div>
                </b-form-group>
              </b-modal>
              </b-form>
            </validation-observer>          
            </b-tab>

          </b-tabs>
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('updateSEO')">
          <b-card no-body>
            <b-card-header>
              <b-card-title title-tag="h5">Calculator SEO Settings</b-card-title>
            </b-card-header>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormSEO"
            >
            <b-form @submit.prevent="handleSubmit(submitSEO)">
              <b-card-body>
                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_id" />
                      <VValidate 
                        name="Meta Title ID" 
                        v-model="seoPageSetting.sss_meta_title_id"
                        :rules="{...seoValidation.sss_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_en" />
                      <VValidate 
                        name="Meta Title EN" 
                        v-model="seoPageSetting.sss_meta_title_en"
                        :rules="{...seoValidation.sss_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_id" />
                      <VValidate 
                        name="Meta Description ID" 
                        v-model="seoPageSetting.sss_meta_description_id"
                        :rules="{...seoValidation.sss_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_en" />
                      <VValidate 
                        name="Meta Description EN" 
                        v-model="seoPageSetting.sss_meta_description_en"
                        :rules="{...seoValidation.sss_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_id" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_en" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>
<script>

import GlobalVue from '@/libs/Global.vue'
import BoCardImg from '@/components/backend/BoCardImg.vue'
import Gen from '@/libs/Gen'
const _ = global._


export default {
  extends: GlobalVue,
  
  components: {
    BoCardImg
  },
  mounted() {
    this.apiGet()
    this.getAllData()
    this.doChangeTP()
    this.doFilterComp()
  },
  data() {
    return {
      heroEditorOpen: false,
      heroImage: {},
      validationHeroImage: {},
      seoPageSetting: {},
      seoValidation: {},
      calculatorPlatData: [],
      inputPlat:{},
      filterActiveArea: '',
      filterArea: '',
      filterActiveGuarantee: '',
      calculatorAddData: [],
      inputAdd:{},
      tpLimit:{},
      formCat:{},
      dataPS:{},
      dataComp:{},
      dataTLO:{}, 

      compreArea: 1,
      compreCat: 1,
      tloArea: 1,
      tloCat: 6,
      catOptions: [
        { label: 'CATEGORY 1', id: 1 },
        { label: 'CATEGORY 2', id: 2 },
        { label: 'CATEGORY 3', id: 3 },
        { label: 'CATEGORY 4', id: 4 },
        { label: 'CATEGORY 5', id: 5 },
      ],


      areaFields: [
        { key: 'no', label: '#' },
        { key: 'region', sortable: true, label: 'Area' },
        { key: 'cat', label: 'Category', sortable: true },
        'status', 'actions',
      ],
      areaItems: [
        { code: 'A', region: 'Banten', status: 'Y', cat: 2 },
        { code: 'AA', region: 'Kedu', status: 'Y', cat: 3 },
        { code: 'AB', region: 'Yogyakarta', status: 'Y', cat: 3 },
        { code: 'AD', region: 'Surakarta', status: 'N', cat: 3 },
      ],
      viewDataOptions: [25, 50, 100],
      areaPerPage: 25,
      areaCurPage: 1,
      areaCategories: [
        { label: 'WILAYAH 1 / AREA 1', id: 1 },
        { label: 'WILAYAH 2 / AREA 2', id: 2 },
        { label: 'WILAYAH 3 / AREA 3', id: 3 },
      ],
      areaFilterCategories: [
        { label: 'All Area Category', id: '' },
        { label: 'WILAYAH 1 / AREA 1', id: 1 },
        { label: 'WILAYAH 2 / AREA 2', id: 2 },
        { label: 'WILAYAH 3 / AREA 3', id: 3 },
      ],
      
      addGuaranteeFields: [
        { key: 'no', label: '#', thAttr: { width: '64' } },
        { key: 'name', sortable: true },
        { key: 'showCalc', label: 'Shown in Calculator' },
        'status', 'actions',
      ],
      addGuaranteeItems: [
        { name: { id: 'Banjir dan Topan', en: 'Flood and Typhoon' }, relate: 1, status: 'Y', showCalc: 'Y', show: 'B' },
        { name: { id: 'Water Hammer', en: 'Water Hammer' }, status: 'Y', showCalc: 'N', show: 'B' },
        { name: { id: 'Gempa dan Tsunami', en: 'Earthquake and Tsunami' }, status: 'Y', showCalc: 'Y', show: 'B' },
        { name: { id: 'Huru Hara', en: 'Strike, Riot, Civil, Commotion' }, status: 'Y', showCalc: 'Y', show: 'B' },
        { name: { id: 'Terorisme dan Sabotase', en: 'Terrorism and Sabotage' }, relate: 3, status: 'Y', showCalc: 'Y', show: 'B' },
        { name: { id: 'Bengkel Resmi', en: 'Authorized Workshop' }, status: 'Y', showCalc: 'Y', show: 'C' },
        { name: { id: 'Mobil Pengganti', en: 'Replacement Car' }, status: 'Y', showCalc: 'Y', show: 'C' },
        { name: { id: 'Pencurian oleh Supir', en: 'Theft by Driver' }, status: 'Y', showCalc: 'Y', show: 'C' },
      ],
      addGuaranteePerPage: 10,
      addGuaranteeCurPage: 1,
      guaranteeAvailability: [
        {item : 'Comprehensive', value: 'C'},
        {item : 'Total Loss Only', value: 'T'}
      ],
      mrCalculatorAdd : [],
      guaranteeIncludes: [
        'Banjir dan Topan / Flood and Typhoon',
        'Water Hammer / Water Hammer',
        'Gempa dan Tsunami / Earthquake and Tsunami',
        'Huru Hara / Strike, Riot, Civil, Commotion',
        'Terorisme dan Sabotase / Terrorism and Sabotage',
        'Bengkel Resmi / Authorized Workshop',
        'Mobil Pengganti / Replacement Car',
        'Pencurian oleh Supir / Theft by Driver',
      ],

      thirdCatOptions: [],
      thirdCat: 1,
      statusOptions: [
        { label: 'All Status', value: '' },
        { label: 'Active', value: 'Y' },
        { label: 'Inactive', value: 'N' },
      ],
      dataRow: {},
      seoRow: {},
      filterRow: {
        areaFilterCategory: '',
        filterStatus: '',
      },
      hasTp :[]
    }
  },
  methods: {
    autoFill(to = "", to2 = "", from = ""){
      this.heroImage[to] = this.heroImage[from]
      this.heroImage[to2] = this.heroImage[from]    
    },
    changeHero(){
      this.heroEditorOpen = !this.heroEditorOpen
    },
    openAreaForm(row={}, index = false){
      this.inputPlat = JSON.parse(JSON.stringify(row))
      this.inputPlat.index = index 
      this.$set(this.inputPlat, 'mcp_id', this.inputPlat.mcp_id||'')
      this.$set(this.inputPlat, 'mcp_code_plat', this.inputPlat.mcp_code_plat||'')
      this.$set(this.inputPlat, 'mcp_wilayah', this.inputPlat.mcp_wilayah||'')
      this.$set(this.inputPlat, 'mcp_wilayah_plat_en', this.inputPlat.mcp_wilayah_plat_en||'')
      this.$set(this.inputPlat, 'mcp_wilayah_plat_id', this.inputPlat.mcp_wilayah_plat_id||'')  
      this.$bvModal.show('formArea')
    },
    openAddGuaranteeForm(row={}, index = false){
      this.$bvModal.show('guaranteeForm')
      this.inputAdd = JSON.parse(JSON.stringify(row))
      this.inputAdd.index = index 
      this.$set(this.inputAdd, 'mcag_id', this.inputAdd.mcag_id||'')
      this.$set(this.inputAdd, 'showCat', this.inputAdd.showCat||[])
      this.$set(this.inputAdd, 'showCal', this.inputAdd.showCal||[])
      this.$set(this.inputAdd, 'mcag_name_id', this.inputAdd.mcag_name_id||'')
      this.$set(this.inputAdd, 'mcag_name_en', this.inputAdd.mcag_name_en||'')
      this.$set(this.inputAdd, 'mcag_is_active', this.inputAdd.mcag_is_active||'Y')
      this.$set(this.inputAdd, 'relatedTo', this.inputAdd.relatedTo||[])  
    },
    getAllData(){
      this.loadingOverlay=true
      this.restAPI('calculator-plat')
      this.restAPI('calculator-add')
    },
    restAPI(slug, params=false, callback=false){
      Gen.apiRest(
        "/get/"+this.modulePage+"/"+slug,
        {
          params:params||{}
        }
      ).then(res=>{
        if(slug == "tp-limit"){
          let index = this.hasTp.findIndex(v => v.id == res.data.formCat.id)
          if(index > -1){
            this.formCat = this.hasTp[index]
            this.thirdCatOptions = res.data.thirdCatOptions,
            this.tpLimit = res.data.tpLimit
          } else {
            _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
            })
            this.hasTp.push(res.data.formCat)
          }


          this.loadingOverlay=false
          if(callback) callback()
          return true
        }

        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay=false
        if(callback) callback()
      })
    },
    doChangeStatus(type,v,idKey,statusKey){
      let changeStatus = v[statusKey] == "Y" ? "N" : "Y"
      this.$swal({
        title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.loadingOverlay=true

          Gen.apiRest(
            "/do/"+this.modulePage, 
            {data: Object.assign({}, {
              type: type, 
              id:v[idKey],
              status: changeStatus
            })}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              v[statusKey] = changeStatus

              this.$swal({
                title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                icon: 'success',
              })
            })
        }
      })
    },
    doDelete(type,v,idKey){
      this.$swal({
        title: 'Delete this Calculator ?',        
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.loadingOverlay = true

          Gen.apiRest(
            "/do/"+this.modulePage, 
            {data: Object.assign({}, {
              type: type, 
              id: v[idKey]
            })}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              this.doFilterComp()
              this.restAPI('calculator-plat')
              this.restAPI('calculator-add')
              this.$swal({
                title: 'Data Deleted Successfully!',
                icon: 'success',
              })
            })
        }
      })
    },
    doFilterArea(){
      let params ={
        ...this.filter,
        filterArea : this.filterArea 
      }
      this.restAPI('calculator-plat', params)
    },
    doFilterActiveArea(){
      let params ={
        ...this.filter,
        filterActiveArea : this.filterActiveArea 
      }
      this.restAPI('calculator-plat', params)
    },
    dofilterActiveGuarantee(){
      let params ={
        ...this.filter,
        filterActiveGuarantee : this.filterActiveGuarantee
      }
      this.restAPI('calculator-add', params)
    },
    doFilter(slug, params={}){
      this.restAPI(slug, params)
    },
    doReset(slug){
      this.restAPI(slug)
      this.filter.searchArea = ''    
      this.filterArea = ''
      this.filterActiveArea = ''
    },

    doSubmitGuarantee(bvModalEvt){
      if(bvModalEvt){
          bvModalEvt.preventDefault()
      }

      this.$refs['VFormGuarantee'].validate().then(success => {
        if (!success) return
        this.loadingOverlay = true
        const inputAdd = this.inputAdd
        const data = JSON.parse(JSON.stringify(inputAdd))

        Gen.apiRest(
        "/do/"+this.modulePage, 
        {data: Object.assign({}, data, {
            type:inputAdd.index===false?'add-guarantee':'update-guarantee', 
            id:inputAdd.id
        })}, 
        "POST"
        ).then(res=>{
            this.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.doSetAlertForm(resp)
            this.restAPI('calculator-add')
            this.doFilterComp()
            this.$bvModal.hide('guaranteeForm')

        }).catch(err=>{
            this.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.doSetAlertForm(err)
        })
      })
    },
    formatDecimal(data) {
      return new Intl.NumberFormat('id-ID', ({ style: 'decimal' })).format(data)
    },
    doChangeTP(){
      this.restAPI('tp-limit',{cat: this.thirdCat})
    },
    doFilterComp(){
      this.restAPI('general',{areaComp: this.compreArea, catComp: this.compreCat, areaTLO: this.tloArea, catTLO: this.tloCat})
    },
    doSubmitArea(bvModalEvt){
        if(bvModalEvt){
            bvModalEvt.preventDefault()
        }
        this.$refs['VFormArea'].validate().then(success => {
            if (!success) return
            
            this.loadingOverlay = true
            const inputPlat = this.inputPlat
            const data = JSON.parse(JSON.stringify(inputPlat))

            Gen.apiRest(
            "/do/"+this.modulePage, 
            {data: Object.assign({}, data, {
                type:inputPlat.index===false?'add-plat':'update-plat', 
                id:inputPlat.id
            })}, 
            "POST"
            ).then(res=>{
                this.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.doSetAlertForm(resp)
                this.restAPI('calculator-plat')
                this.$bvModal.hide('formArea')

            }).catch(err=>{
                this.loadingOverlay = false
                
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                }

                this.doSetAlertForm(err)
            })

        })
    },
    doSubmitPolicy(){
      this.dataPS.value = this.dataPS.value.toString().replace(/[.,]/g, '')
      const form = this.dataPS
      const data = JSON.parse(JSON.stringify(form))
      this.loadingOverlay = true
      Gen.apiRest(
      "/do/"+this.modulePage, 
      {data: Object.assign({}, data, {
          type:'update-policy'
      })}, 
      "POST"
      ).then(res=>{
          this.loadingOverlay = false
          let resp = res.data
          resp.statusType = 200
          this.doSetAlertForm(resp)
          this.restAPI('general')
      }).catch(err=>{
          this.loadingOverlay = false
          
          if(err){
              err.statusType = err.status
              err.status = "error"
              err.message = err.response?.data?.message
              err.messageError = err.message
          }

          this.doSetAlertForm(err)
      })
    },
    doSubmitTLO(bvModalEvt){
      if(bvModalEvt){
          bvModalEvt.preventDefault()
      }
      this.loadingOverlay = true            
      
      for (let i = 0; i < this.dataTLO.limit.length; i++) {
        this.dataTLO.limit[i].mccr_start_value = this.dataTLO.limit[i].mccr_start_value.toString().replace(/[.,]/g, '') 
        this.dataTLO.limit[i].mccr_end_value = this.dataTLO.limit[i].mccr_end_value.toString().replace(/[.,]/g, '')
      }
      this.dataTLO.area = this.tloArea
      this.dataTLO.cat = this.tloCat

      const dataTLO = this.dataTLO
      const data = JSON.parse(JSON.stringify(dataTLO))

      Gen.apiRest(
      "/do/"+this.modulePage, 
      {data: Object.assign({}, data, {
          type:'update-comp-tlo'
      })}, 
      "POST"
      ).then(res=>{
          this.loadingOverlay = false
          let resp = res.data
          resp.statusType = 200
          this.doSetAlertForm(resp)
          this.doFilterComp()
          // this.restAPI('tp-limit',{cat: this.thirdCat})
      }).catch(err=>{
          this.loadingOverlay = false
          
          if(err){
              err.statusType = err.status
              err.status = "error"
              err.message = err.response?.data?.message
              err.messageError = err.message
          }

          this.doSetAlertForm(err)
      })

    },
    doSubmitComp(bvModalEvt){
      if(bvModalEvt){
          bvModalEvt.preventDefault()
      }

      this.loadingOverlay = true            
      
      for (let i = 0; i < this.dataComp.limit.length; i++) {
        this.dataComp.limit[i].mccr_start_value = this.dataComp.limit[i].mccr_start_value.toString().replace(/[.,]/g, '') 
        this.dataComp.limit[i].mccr_end_value = this.dataComp.limit[i].mccr_end_value.toString().replace(/[.,]/g, '')
      }
      this.dataComp.area = this.compreArea
      this.dataComp.cat = this.compreCat

      const dataComp = this.dataComp
      const data = JSON.parse(JSON.stringify(dataComp))

      Gen.apiRest(
      "/do/"+this.modulePage, 
      {data: Object.assign({}, data, {
          type:'update-comp-data'
      })}, 
      "POST"
      ).then(res=>{
          this.loadingOverlay = false
          let resp = res.data
          resp.statusType = 200
          this.doSetAlertForm(resp)
          this.doFilterComp()
          // this.restAPI('tp-limit',{cat: this.thirdCat})
      }).catch(err=>{
          this.loadingOverlay = false
          
          if(err){
              err.statusType = err.status
              err.status = "error"
              err.message = err.response?.data?.message
              err.messageError = err.message
          }

          this.doSetAlertForm(err)
      })

    },
    doSubmitTP(bvModalEvt){
        if(bvModalEvt){
            bvModalEvt.preventDefault()
        }
        this.$refs['VFormTP'].validate().then(success => {
            if (!success) return
            
            this.loadingOverlay = true
            this.formCat.id = this.thirdCat                        
            if(this.thirdCat !== 'P' && this.thirdCat !== 'D'){
              this.formCat.lower_limit = this.formCat.lower_limit.toString().replace(/[.,]/g, '') 
              this.formCat.upper_limit = this.formCat.upper_limit.toString().replace(/[.,]/g, '') 
              this.formCat.id = this.thirdCat            
            }
            const formCat = this.formCat
            const data = JSON.parse(JSON.stringify(formCat))

            Gen.apiRest(
            "/do/"+this.modulePage, 
            {data: Object.assign({}, data, {
                type:'update-tp'
            })}, 
            "POST"
            ).then(res=>{
                this.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.doSetAlertForm(resp)
                this.restAPI('tp-limit',{cat: this.thirdCat})
            }).catch(err=>{
                this.loadingOverlay = false
                
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                }

                this.doSetAlertForm(err)
            })

        })
    },
    
  },
  watch: {
    $route(){
      this.apiGet()
      this.getAllData()
    },
    areaCurPage(v){
      this.processPage=true
      this.restAPI('calculator-plat', {page:v}, ()=>{
        this.processPage=false
      })
    },
    addGuaranteeCurPage(v){
      this.processPage=true
      this.restAPI('calculator-add', {page:v}, ()=>{
        this.processPage=false
      })
    },
    
    // counter seo
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
  }
}
</script>